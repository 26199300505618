// state
export const state = {
    pageNo: null,
    onlyMine: true,
    searchType: null,
    searchQuery: null,
    onlyReqst: false,
    onlyActive: false
};


// getters
export const getters = {
    pageNo: state => state.pageNo,
    onlyMine: state => state.onlyMine,
    searchType: state => state.searchType,
    searchQuery: state => state.searchQuery,
    onlyReqst: state => state.onlyReqst,
    onlyActive: state => state.onlyActive,
};

// mutations
export const mutations = {
    setPageNo: (state, newPageNo) => {
        if (newPageNo) {
            state.pageNo = newPageNo;
        }
    },
    setOnlyMine: (state, newOnlyMine) => {
        state.onlyMine = newOnlyMine;
    },
    setSearchType: (state, type) => {
        state.searchType = type;
    },
    setSearchQuery: (state, query) => {
        state.searchQuery = query;
    },
    setOnlyReqst: (state, newOnlyReqst) => {
        state.onlyReqst = newOnlyReqst;
    },
    setOnlyActive: (state, newOnlyActive) => {
        state.onlyActive = newOnlyActive;
    },
};

// actions
export const actions = {
    setPageNo({ commit }, newPageNo) {
        commit('setPageNo', newPageNo);
    },
    setOnlyMine({ commit }, newOnlyMine) {
        commit('setOnlyMine', newOnlyMine);
    },
    setSearchType({ commit }, type) {
        commit('setSearchType', type);
    },
    setSearchQuery({ commit }, query) {
        commit('setSearchQuery', query);
    },
    setOnlyReqst({ commit }, newOnlyReqst) {
        commit('setOnlyReqst', newOnlyReqst);
    },
    setOnlyActive({ commit }, newOnlyActive) {
        commit('setOnlyActive', newOnlyActive);
    },
};