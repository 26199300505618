import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async() => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            user: '',
        };
        const lang = localStorage.getItem('pdx-lang');
        try {
            const res = await APIReq(`${API_URL}/auth/get/data`, {lang}, 'post');
            if (res && res.data && res.data.result) {          
                retData.isSuccess = true;
                retData.user = res.data.data
                retData.role = res.data.data.role
            } else if (res && res.data && res.data.error) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.errorMsg = e.response.data.message ?? API_COMMON_ERROR_MSG;
        }
        return retData;
    }
};
export default api