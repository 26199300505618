import en from "./i18nData/en.json";
import ko from "./i18nData/ko.json";
import VueI18n from "vue-i18n";
import Vue from "vue";
Vue.use(VueI18n);

export default new VueI18n({
  locale:  localStorage.getItem('pdx-lang') ?? 'ko',
  fallbackLocale: 'en',
  messages: {
    ko: ko,
    en: en,
  }
})