// state
export const state = {
    popupList: [],
};

// getters
export const getters = {
    popupList: state => state.popupList,
};

// mutations
export const mutations = {
    addPopup: (state, popup) => {
        if (state.popupList && state.popupList.indexOf(popup) < 0) {
            state.popupList.push(popup);
        }
    },
    removePopup: (state, popup) => {
        if (state.popupList.indexOf(popup) > -1) {
            state.popupList.splice(state.popupList.indexOf(popup), 1);
        }
    }
};

// actions
export const actions = {
    addPopup({ commit }, popup) {
        commit('addPopup', popup);
    },
    removePopup({ commit }, popup) {
        commit('removePopup', popup);
    }
};