
export default [
    {
        path: '',
        redirect: '/reading',
        components: {
            default : () => import('../views/main.vue'),
        },
        children: [
            {
                path: '/reading',
                name: 'step1',
                component: () => import('../views/user/reading/step1.vue'),
                meta: { requiresAuth: true, requiredRole: 'U' }
            },
            {
                path: '/reading/step2',
                name: 'step2',
                component: () => import('../views/user/reading/step2.vue'),
                meta: { requiresAuth: true, requiredRole: 'U' }
            },
            {
                path: '/patients',
                name: 'patients',
                component: () => import('../views/user/patients/list.vue'),
                meta: { requiresAuth: true, requiredRole: 'U' }
            },
            {
                path: '/patients/:no',
                name: 'patient',
                component: () => import('../views/user/patients/detail.vue'),
                meta: { requiresAuth: true, requiredRole: 'U' }
            },
            {
                path: '/patients/record/:type/:no',
                name: 'patientRecord',
                component: () => import('../views/user/record/record.vue'),
                meta: { requiresAuth: true, requiredRole: 'U' }
            },
            {
                path: '/admin/userManage/',
                name: 'userManage',
                component: () => import('../views/admin/userManage/list.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
            {
                path: '/admin/userManage/regist',
                name: 'userRegist',
                component: () => import('../views/admin/userManage/regist.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
            {
                path: '/admin/userManage/:no',
                name: 'userDetail',
                component: () => import('../views/admin/userManage/detail.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
            {
                path: '/admin/patientManage/',
                name: 'patientManage',
                component: () => import('../views/admin/patientManage/list.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
            {
                path: '/admin/transferManage/',
                name: 'transferManage',
                component: () => import('../views/admin/transferManage/list.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
            {
                path: '/admin/transferManage/:no',
                name: 'transferInfo',
                component: () => import('../views/admin/transferManage/detail.vue'),
                meta: { requiresAuth: true, requiredRole: 'A' }
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/report/:no',
        name: 'totReport',
        component: () => import('../views/user/report/totReport.vue'),
        meta: { requiresAuth: true, requiredRole: 'U' }
    },
    {
        path: '/report/spine/:no',
        name: 'spineReport',
        component: () => import('../views/user/report/spineReport.vue'),
        meta: { requiresAuth: true, requiredRole: 'U' }
    },
    {
        path: '/imageView/:src',
        name: 'imageView',
        component: () => import('../views/user/reading/imageView.vue'),
        meta: { requiresAuth: true, requiredRole: 'U' }
    },
    {
      path: "/notFound",
      name: "notFound",
      component: () => import('../views/errors/404.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/notFound"
    },
];