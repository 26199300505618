import store from '../store'

export default async(to, from, next) => {
    if (store.getters['auth/isLogin']) {
        next();
    } else {
        if(!to.name) {
            if(store.getters['auth/userRole'] === 'A') next({name: 'userManage'});
            else next({name: 'step1'});
        } else {
            next({name: 'login'});
        }
    }
};