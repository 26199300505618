import axios from "axios";
import Vue from 'vue';

axios.interceptors.request.use(config => {
  Vue.prototype.$Progress.start(); 
  return config;
});

axios.interceptors.response.use(response => {
  Vue.prototype.$Progress.finish(); 
  return response;
}, error => {
  Vue.prototype.$Progress.fail(); 
  return Promise.reject(error);
});

const request = async (url, data, method = 'post', headers = {}) => {
    const res = await axios({
        method: method,
        url: url,
        data: method.toLocaleLowerCase() === 'post' ? data : null,
        params: method.toLocaleLowerCase() === 'post' ? null : data,
        headers: headers
    });
    if (res) {
        return res;
    }
}

export default request;