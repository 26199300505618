import EventBus from "./EventBus";

const AppUtil = {
    sleep: (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, ms)
        });
    },
    // 천단위 콤마 처리
    numberWithCommas: (x) => {
        try {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } catch (e) {
            return x ? x : '0';
        }
    },
    // 줄바꿈 문자열 <br>로 치환 처리
    replaceLnToBr: (str) => {
        let text = str
        text = text.replace(/&/g, "&amp;")
        text = text.replace(/</g, "&lt;")
        text = text.replace(/>/g, "&gt;")
        text = text.replace(/"/g, "&quot;")
        text = text.replace(/'/g, "&apos;")
        text = text.replace(/\r\n/g, "<br>")
        text = text.replace(/\\r\\n/g, "<br>")
        text = text.replace(/\n/g, "<br>")
        text = text.replace(/\\n/g, "<br>")
        return text
    },
    isIE: () => {
        let agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf("chrome") != -1 || agent.indexOf("safari") != -1 || agent.indexOf("firefox") != -1) {
            return false;
        }
        let isIE = /*@cc_on!@*/ false || !!document.documentMode;
        return isIE;
    },
    
    eventOn(id, func) {
        EventBus.on(id, func);
    },
    
    eventOff(id, func = null) {
        EventBus.off(id, func);
    },
    
    eventEmit(id, data = null) {
        EventBus.emit(id, data);
    },
    
    getUrlForm(strUrl) {
        var expUrl = /^http[s]?:\/\//i;
        if (!expUrl.test(strUrl)) {
            return 'https://' + strUrl
        }
        return strUrl;
    }
};

export default AppUtil;