import CookieUtil from "../../plugins/CookieUtil";
import myinfo from '../../API/user/myinfo';
import logout from '../../API/user/logout';

// state
export const state = {
    userInfo: null,
    userRole: null
};

// getters
export const getters = {
    isLogin: state => state.userInfo ? true : false,
    userInfo: state => state.userInfo,
    userRole: state => state.userInfo && state.userInfo.role
};

// mutations
export const mutations = {
    ['userInfo'](state, userInfo) {
        state.userInfo = userInfo
        CookieUtil.set('userInfo', userInfo);
        localStorage.setItem('isLogin',true)
    },
    ['userRole'](state, userRole) {
        state.userRole = userRole
        CookieUtil.set('userRole', userRole);
        localStorage.setItem('userRole',userRole)
    }
};

// actions
export const actions = {
    async fetchData({ commit }) {
        let res = await myinfo.request();
        if (res.isSuccess) {
            commit('userInfo', res.user);
            localStorage.setItem('isLogin',true)
            commit('userRole', res.role);
            localStorage.setItem('userRole',res.role)
            return true;
        } else {
            commit('userInfo', null);
            CookieUtil.remove('userInfo');
            if(localStorage.getItem('isLogin')) localStorage.removeItem('isLogin')
            commit('userRole', null);
            CookieUtil.remove('userRole');
            if(localStorage.getItem('userRole')) localStorage.removeItem('userRole')
            return false;
        }
    },
    async logout({ commit }) {
        let res = await logout.request()
        if (res.isSuccess) {
            commit('userInfo', null);
            this.userInfo = null;
            CookieUtil.remove('userInfo');
            if(localStorage.getItem('isLogin')) localStorage.removeItem('isLogin')

            commit('userRole', null);
            this.userRole = null;
            CookieUtil.remove('userRole');
            if(localStorage.getItem('userRole')) localStorage.removeItem('userRole')
        }
    }
};
