import Cookie from "js-cookie";

const CookieUtil = {
    async get(key) {
        return Cookie.get(key);
    },
    set(key, value, option = { expires: null }) {
        Cookie.set(key, value, option);
    },
    remove(key) {
        Cookie.remove(key);
    }
};

export default CookieUtil;