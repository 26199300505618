import i18n from '../i18n'

export const baseRemSize = 16;

export const API_URL = '/api';
export const API_COMMON_ERROR_MSG = i18n.t('common.msg.apiError')

const Constants = {
    OK: i18n.t('common.button.ok'),
    CANCEL: i18n.t('common.button.cancel'),
    REG: i18n.t('common.button.reg'),
    MOD: i18n.t('common.button.mod'),
    DELETE: i18n.t('common.button.del'),
    LIST: i18n.t('common.button.list'),
    CLOSE: i18n.t('common.button.close'),
    CONFM: i18n.t('common.button.confm'),
    RETURN: i18n.t('common.button.return'),
    READING_SPINE_NAME: i18n.t('common.analysis.type.spine'),
    READING_HANDS_NAME: i18n.t('common.analysis.type.hands'),
    XRAY_TYPE_SPINE: 'spine',
    XRAY_TYPE_HANDS: 'hands',
    XRAY_TYPE_BOTH: 'both',
    SPINE_APEX: i18n.t('common.analysis.result.spine.apex'),
    SPINE_DIRECTION: i18n.t('common.analysis.result.spine.dir'),
    STATE_REQST: '신청대기',
    STATE_CONFM: '이관완료',
    STATE_CANCL: '신청취소',
    STATE_RETURN: '신청반려'
}

export default Constants;